import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firestore, storage } from './firebase';

import AddProductForm from './AddProductForm';
import EditProductForm from './EditProductForm';
import DashBoardLoginForm from './DashBoardLoginForm';
import './ProductManagement.css';

const ProductManagement = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [products, setProducts] = useState([]);
    const [editingProduct, setEditingProduct] = useState(null);

    // Function to handle login
    const handleLogin = () => {
        setIsLoggedIn(true);
    };

    // Fetch products from Firestore
    useEffect(() => {
        if (!isLoggedIn) return;
        const unsubscribe = onSnapshot(collection(firestore, 'products'), (snapshot) => {
            const fetchedProducts = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setProducts(fetchedProducts);
        });

        return () => unsubscribe();
    }, [isLoggedIn]);

    // Add new product
    const handleAddProduct = async (newProduct) => {
        try {
            // Validate form fields
            if (!newProduct.name || !newProduct.description || !newProduct.price || !newProduct.images || newProduct.images.length === 0 || !newProduct.category) {
                alert('Please fill in all fields.');
                return;
            }

            const imageURLs = [];
            for (const image of newProduct.images) {
                const storageRef = ref(storage, 'images/' + image.name);
                await uploadBytes(storageRef, image);
                const imageURL = await getDownloadURL(storageRef);
                imageURLs.push(imageURL);
            }

            // Add product data to Firestore collection
            await addDoc(collection(firestore, 'products'), {
                name: newProduct.name,
                description: newProduct.description,
                price: newProduct.price,
                imageURLs: imageURLs,
                category: newProduct.category // Include category in the product data
            });

            alert('Product Added Successfully!');
        } catch (error) {
            console.error('Error adding product:', error);
            alert('Failed to add product. Please try again.');
        }
    };

    // Edit existing product
    const handleEditProduct = async (productId, updatedFields) => {
        try {
            const productRef = doc(firestore, 'products', productId);

            // Check if new images are selected
            if (updatedFields.images && updatedFields.images.length > 0) {
                const imageURLs = [];
                for (const image of updatedFields.images) {
                    const storageRef = ref(storage, 'images/' + image.name);
                    await uploadBytes(storageRef, image);
                    const imageURL = await getDownloadURL(storageRef);
                    imageURLs.push(imageURL);
                }
                // Update the imageURLs field with the new image URLs
                updatedFields.imageURLs = imageURLs;
                // Remove the 'images' field as it's not supported by Firestore directly
                delete updatedFields.images;
            }

            await updateDoc(productRef, updatedFields);
            alert('Product Updated Successfully');
        } catch (error) {
            console.error('Error updating product:', error);
            alert('Failed to update product. Please retry at a later time');
        }
    };

    // Delete product
    const handleDeleteProduct = async (productId) => {
        try {
            const confirmed = window.confirm('Are you sure you want to delete this product permanently?');
            if (!confirmed) {
                return;
            }
            await deleteDoc(doc(firestore, 'products', productId));
            setProducts(prevProducts => prevProducts.filter(product => product.id !== productId));
        } catch (error) {
            console.error('Error deleting product:', error);
            alert('Failed to delete product. Please try again.');
        }
    };

    // Cancel editing
    const handleCancelEdit = () => {
        setEditingProduct(null);
    };

    return (
        <div className='product-management'>
            {!isLoggedIn ? (
                <DashBoardLoginForm onLogin={handleLogin} />
            ) : (
                <>
                    <h2>Manage</h2>
                    {editingProduct ? (
                        <EditProductForm
                            product={editingProduct}
                            onEditProduct={handleEditProduct}
                            onCancel={handleCancelEdit}
                        />
                    ) : (
                        <>
                            <AddProductForm onAddProduct={handleAddProduct} />
                            <h3>Product List</h3>
                            <ul>
                                {products.map(product => (
                                    <li key={product.id}>
                                        {product.name} - €{product.price}
                                        <button className="delete-button" onClick={() => handleDeleteProduct(product.id)}>Delete</button>
                                        <button className="edit-button" onClick={() => setEditingProduct(product)}>Edit</button>
                                        <p>Category: {product.category}</p> {/* Display category */}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default ProductManagement;
