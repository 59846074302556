import React from 'react';
import './HomePage.css';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div className="home-page">
      <header>
        <h1 className="company-name">Kakalika.gr</h1>
        <nav>
          <Link to="/products" className="cta-button">Browse Products</Link>
        </nav>
      </header>
      <section className="hero-section" style={{ backgroundImage: `url('/background1.webp')` }}>
        <div className="hero-content">
          <p>Discover our wide range of products and expert repair services to meet all your electronic needs.</p>
          <Link to="/products" className="cta-button">Visit Us Now</Link>
        </div>
      </section>
      <section className="about-section">
        <h2>About Us</h2>
        <p>
          At Kakalika, we specialize in providing high-quality electronics products and repair services. From TVs and laptops to smartphones and gaming consoles, we offer a wide range of products to meet your needs. Our experienced technicians are also available to provide expert repair services to ensure that your electronics are always in top condition.
        </p>
      </section>
      <section className="repair-service-section">
        <h2>Repair Services</h2>
        <p>Our skilled technicians are here to help you with all your repair needs. You don't need to trash your faulty electronic gadgets; our skilled and talented technicians are available to make them work again. SAVE MONEY by bringing them for repairs.</p>
        <div className="repair-services-images">
          <img src="/galaxy2.webp" alt="Repair Service" className="repair-service"/> 
        </div>
        <div className="repair-services-list">
          <div className="repair-service-item">
            <h3>Data Recovery</h3>
            <p>Accidentally deleted important files? Our data recovery experts can help retrieve lost data from a variety of devices.</p>
          </div>
          <div className="repair-service-item">
            <h3>Virus Removal</h3>
            <p>Is your device infected with viruses or malware? Our technicians can perform thorough virus scans and remove malicious software to restore your device's security.</p>
          </div>
          <div className="repair-service-item">
            <h3>Hardware Repairs</h3>
            <p>Experiencing hardware issues such as broken screens or malfunctioning components? Our technicians can diagnose and repair a wide range of hardware problems.</p>
          </div>
          <div className="repair-service-item">
            <h3>Software Troubleshooting</h3>
            <p>Having trouble with software issues like system crashes or performance slowdowns? Our experts can troubleshoot and resolve software problems to ensure your device runs smoothly.</p>
          </div>
          <div className="repair-service-item">
            <h3>Device Upgrades</h3>
            <p>Want to improve the performance of your device? Our technicians can help with upgrades such as RAM expansion or SSD installation to enhance your device's capabilities.</p>
          </div>
        </div>
      </section>
      <section className="product-showcase-section">
        <h2>Featured Products</h2>
        <p>Not only do we repair electronic gadgets, we also sell them. These are the kinds of items we sell, with good quality. Browse through our products to find what you need.</p>
        <div className="product-showcase">
          <img src="/flatscreen1.webp" alt="Tv" />
          <img src="/headphone1.webp" alt="Headphone" />
          <img src="/scooter1.webp" alt="Scooter" />
          <img src="/laptop1.webp" alt="Laptop" />
          <img src="/tv1.jpg" alt="Tv" />
          <img src="/galaxy6.webp" alt="Galaxy" />
          <img src="/tablet.webp" alt="Tablet" />
          <img src="/monitor4.jpg" alt="Monitor" />
          <img src="/monitor1.webp" alt="Monitor" />
          <img src="/headphone2.webp" alt="Headphone" />
          <img src="/monitor4.jpg" alt="Monitor" />
          <img src="/stove1.webp" alt="Stove" />
          <img src="/aircon1.webp" alt="Air Conditioner" />
          <img src="/washing2.webp" alt="Washing Machine" />
        </div>
      </section>

      {/* New Contact Section */}
      <section className="contact-section">
        <h2>Contact Us</h2>
        <p>If you have any questions or inquiries, please feel free to contact us:</p>
        <ul>
          <li><a href="mailto:galaxyelectronicx@gmail.com">Send Email</a></li>
          <li>Phone: <a href="tel:+306971660983">+306971660983</a></li>
          <li>
            <img src="/whatsapp_logo.png" alt="WhatsApp Logo" /> 
            <a href="https://wa.me/+306971660983">Chat on WhatsApp</a>
          </li>
          <li>
            <img src="/viber_logo.svg" alt="Viber Logo" /> 
            <a href="viber://chat?number=+306971660983">Chat on Viber</a>
          </li>
          <li>
            <img src="/messenger_logo.png" alt="Messenger Logo" /> 
            <a href="https://m.me/JackEricAsare">Chat on Messenger</a>
          </li>
        </ul>
      </section>
      <footer>
        <nav>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms-of-service">Terms of Service</Link>
        </nav>
        <p>&copy; 2024 Kakalika. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HomePage;
