import React, { useState, useEffect } from 'react';
import './ProductPage.css';
import { Link } from 'react-router-dom';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from './firebase';

const ProductPage = () => {
  const [products, setProducts] = useState([]); // Setting the product to an empty array
  const [filteredProducts, setFilteredProducts] = useState([]); // To store filtered products
  const [category, setCategory] = useState('All'); // State for selected category

  // Hook to fetch the product from Firebase storage, Firestore.
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(firestore, 'products'), (snapshot) => {
      const fetchedProducts = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(fetchedProducts);
      setFilteredProducts(fetchedProducts); // Initially set filtered products to all products
    });

    return () => unsubscribe();
  }, []);

  // Effect to filter products based on category
  useEffect(() => {
    if (category === 'All') {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(products.filter(product => product.category === category));
    }
  }, [category, products]);

  // JSX
  return (
    <div className="product-page">
      <h1>Our Products</h1>
      <Link to="/" className="home-link">Go Home</Link>

      <div className="category-filter">
        <label htmlFor="category">Filter by Category: </label>
        <select id="category" value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="All">All</option>
          <option value="Electronics">Electronics</option>
          <option value="Clothing">Clothing</option>
          <option value="Home Appliances">Home Appliances</option>
          <option value="Sports">Sports</option>
          <option value="Books">Books</option>
        
        </select>
      </div>

      <div className="product-list">
        {filteredProducts && filteredProducts.length > 0 ? (
          filteredProducts.map((product, index) => (
            <div className="product" key={index}>
              <Link to={`/product/${product.id}`}>
                {product.imageURL && ( // Check if product has a main image URL
                  <img src={product.imageURL} alt={product.name} /> // Display the main image
                )}
                {!product.imageURL && product.imageURLs && product.imageURLs.length > 0 ? ( // If no main image, check for additional images
                  <img src={product.imageURLs[0]} alt={product.name} /> // Display the first additional image
                ) : null}
                {(!product.imageURL && !product.imageURLs) && ( // If neither main image nor additional images are available
                  <p>No image available</p>
                )}
                <h2>{product.name}</h2>
                <p>Price: €{product.price}</p>
              </Link>
            </div>
          ))
        ) : (
          <p>No products available</p>
        )}
      </div>
    </div>
  );
};

export default ProductPage;
