import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './HomePage';
import ProductPage from './ProductPage';
import ProductManagement from './ProductManagement';
import ProductDetailsPage from './ProductDetailsPage'; // Import ProductDetailsPage
import CookieConsent from 'react-cookie-consent';
import PrivatePolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="/product/:id" element={<ProductDetailsPage />} /> {/* Route for ProductDetailsPage */}
          <Route path="/dashboard" element={<ProductManagement />} />
          <Route path="/privacy" element={<PrivatePolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
        </Routes>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="myCookieConsent"
          style={{ background: "#333", fontSize: "14px" }}
          buttonStyle={{ color: "#fff", background: "#007bff", fontSize: "14px" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
          <span style={{ fontSize: "12px" }}>
            <a href="/privacy">Privacy Policy</a> and{" "}
            <a href="/terms-of-service">Terms of Service</a>
          </span>
        </CookieConsent>
      </Router>
    </div>
  );
}

export default App;
