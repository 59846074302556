import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage, firestore } from './firebase';
import './AddProductForm.css';

const AddProductForm = () => {
  const [image, setImage] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !description || !price || !image || !category) {
      alert('Please fill in all fields.');
      return;
    }

    try {
      const storageRef = ref(storage, 'images/' + image.name);
      await uploadBytes(storageRef, image);
      const imageURL = await getDownloadURL(storageRef);

      const newProduct = {
        name,
        description,
        price,
        imageURL,
        category,
      };

      await addDoc(collection(firestore, 'products'), newProduct);
      alert('Product added successfully!');

      setImage(null);
      setName('');
      setDescription('');
      setPrice('');
      setCategory('');
    } catch (error) {
      alert('Failed to add product. Please try again.');
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <form className="add-product-form" onSubmit={handleSubmit}>
      <h3>Add Product</h3>

      <label>Item:</label>
      <input type="file" onChange={handleImageChange} accept="image/*" />

      <label>Title:</label>
      <input type="text" value={name} onChange={(e) => setName(e.target.value)} />

      <label>Description:</label>
      <textarea value={description} onChange={(e) => setDescription(e.target.value)} />

      <label>Price:</label>
      <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} />

      <label>Category:</label>
      <select value={category} onChange={(e) => setCategory(e.target.value)}>
        <option value="">Select Category</option>
        <option value="Electronics">Electronics</option>
        <option value="Clothing">Clothing</option>
        <option value="Home Appliances">Home Appliances</option>
        <option value="Sports">Sports</option>
        <option value="Books">Books</option>
      </select>

      <button type="submit">Add Product</button>
    </form>
  );
};

export default AddProductForm;
