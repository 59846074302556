import React from 'react';

const TermsOfService = () => {
  return (
    <div>
      <h1>Terms of Service</h1>
      <p>
        By accessing or using the Galaxy Computers & Electronics website, you agree to be bound by the following terms and conditions:
      </p>
      <h2>Acceptance of Terms</h2>
      <p>
        These Terms of Service constitute a legally binding agreement between you and Galaxy Computers & Electronics. By accessing or using our website, you agree to be bound by these terms and conditions.
      </p>
      <h2>Use of Content</h2>
      <p>
        All content provided on the Galaxy Computers & Electronics website is for informational purposes only. You may not reproduce, distribute, or transmit any content without prior written permission.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        Galaxy Computers & Electronics shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use our website.
      </p>
      <h2>Governing Law</h2>
      <p>
        These Terms of Service shall be governed by and construed in accordance with the laws of Greece, without regard to its conflict of law provisions.
      </p>
      <h2>Contact Information</h2>
      <p>
        If you have any questions or concerns about our Terms of Service, please contact us at asare6a@gmail.com.
      </p>
    </div>
  );
};

export default TermsOfService;
