import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy outlines how Galaxy Computers & Electronics handles and protects any information collected during the use of our website.
      </p>
      <h2>Information Collection</h2>
      <p>
        Galaxy Computers & Electronics may collect non-personal information automatically as you navigate through the site. This information may include your IP address, browser type, and operating system. This information is used for statistical analysis and to improve the user experience.
      </p>
      <h2>Information Use</h2>
      <p>
        Any personal information voluntarily provided by users through contact forms or email addresses will be used solely for the purpose of responding to inquiries about products and repair services. We do not sell, distribute, or lease personal information to third parties.
      </p>
      <h2>Security Measures</h2>
      <p>
        We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.
      </p>
      <h2>Changes to Policy</h2>
      <p>
        Galaxy Computers & Electronics reserves the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page.
      </p>
      <h2>Contact Information</h2>
      <p>
        If you have any questions or concerns about our Privacy Policy, please contact us at asare6a@gmail.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
