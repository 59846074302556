import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from './firebase';
import './ProductDetailsPage.css';
import FullScreenImage from './FullScreenImage';
import { useSwipeable } from 'react-swipeable';
import ArrowButtons from './ArrowButtons'; // Import the ArrowButtons component

const ProductDetailsPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const docRef = doc(firestore, 'products', id);
        const productDoc = await getDoc(docRef);
        if (productDoc.exists()) {
          setProduct({ id: productDoc.id, ...productDoc.data() });
        } else {
          setError('Product not found');
        }
      } catch (error) {
        setError('Error fetching product details');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();

    return () => {
      // Cleanup function
    };
  }, [id]);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.imageURLs.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + product.imageURLs.length) % product.imageURLs.length);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowRight') {
      handleNextImage();
    } else if (event.key === 'ArrowLeft') {
      handlePrevImage();
    }
  };

  const openFullScreen = (index) => {
    setCurrentImageIndex(index);
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNextImage,
    onSwipedRight: handlePrevImage
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!product) {
    return <p>Product not found</p>;
  }

  return (
    <div className="product-page" onKeyDown={handleKeyDown} tabIndex={0} {...handlers}>
      <h1>Product Details</h1>
      <Link to="/contact" className="contact-link">Contact Us</Link>
      <div className="product-details-container">
        <div className="product-details">
          <h2>{product.name}</h2>
          <p>{product.description}</p>
          <p>Price: €{product.price}</p>
          <div className="image-gallery">
            {product.imageURLs.map((imageUrl, index) => (
              <img
                key={index}
                src={imageUrl}
                alt={product.name}
                onClick={() => openFullScreen(index)}
                style={{ cursor: 'pointer' }}
              />
            ))}
          </div>
          <div className="back-to-products">
            <Link to="/products">Back to Products</Link>
          </div>
        </div>
      </div>
      {isFullScreen && (
        <>
          <FullScreenImage
            src={product.imageURLs[currentImageIndex]}
            alt={product.name}
            onClose={handleCloseFullScreen}
          />
          <ArrowButtons 
            handlePrevImage={handlePrevImage} 
            handleNextImage={handleNextImage} 
          />
        </>
      )}
    </div>
  );
};

export default ProductDetailsPage;
