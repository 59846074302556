import React, { useState, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from './firebase';
import './EditProductForm.css';
//import FullImageDisplay from './FullImageDisplay';


const EditProductForm = ({ product, onEditProduct, onCancel }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [mainImage, setMainImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [existingAdditionalImages, setExistingAdditionalImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  

  useEffect(() => {
    if (product) {
      setName(product.name || '');
      setDescription(product.description || '');
      setPrice(product.price || '');
      setImageURLs(product.imageURLs || []);
      setExistingAdditionalImages(product.imageURLs || []);
      setAdditionalImages([]); // Clear additional images state when loading a new product
    }
  }, [product]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!name || !description || !price) {
      alert('Please fill in all fields.');
      return;
    }

    try {
      const updatedFields = {
        name,
        description,
        price,
      };

      // Upload new main image if selected
      if (mainImage) {
        const storageRef = ref(storage, 'images/' + mainImage.name);
        await uploadBytes(storageRef, mainImage);
        const mainImageURL = await getDownloadURL(storageRef);
        updatedFields.imageURL = mainImageURL;
      }

      // Upload new additional images if selected
      if (additionalImages.length > 0) {
        const updatedImageURLs = [];
        for (const image of additionalImages) {
          const storageRef = ref(storage, 'images/' + image.name);
          await uploadBytes(storageRef, image);
          const imageURL = await getDownloadURL(storageRef);
          updatedImageURLs.push(imageURL);
        }
        // Concatenate new additional images with existing ones
        updatedFields.imageURLs = [...imageURLs, ...updatedImageURLs];
      }

      await onEditProduct(product.id, updatedFields);

      alert('Changes have been made successfully!');

      // Clear form fields and reset state
      setName('');
      setDescription('');
      setPrice('');
      setMainImage(null);
      setAdditionalImages([]);

      // Reset imageURLs state
      setImageURLs([]);
    } catch (error) {
      console.error('Error updating product:', error);
      alert('Failed to update product. Please try again.');
    }
  };

  const handleMainImageChange = (e) => {
    // Set the main image
    const file = e.target.files[0];
    setMainImage(file);
  };

  const handleAdditionalImagesChange = (e) => {
    // Update the additional images
    const filesArray = Array.from(e.target.files);
    setAdditionalImages(filesArray);
  };

  const removeAdditionalImage = (index) => {
    const updatedImages = [...additionalImages];
    updatedImages.splice(index, 1);
    setAdditionalImages(updatedImages);
  };

  const removeExistingAdditionalImage = async (index) => {
    try {
      const updatedImages = [...existingAdditionalImages];
      const removedImageURL = updatedImages.splice(index, 1)[0]; // Remove the image URL from the array
      console.log('Removing image with URL:', removedImageURL); // Log the URL
      setExistingAdditionalImages(updatedImages); // Update the state
  
      // Update the product data in the database to reflect the removal of the image
      const updatedFields = { imageURLs: updatedImages };
      await onEditProduct(product.id, updatedFields);
  
      // Delete the removed image from Firebase Storage if it exists
      const imageFileName = getImageFileName(removedImageURL);
      const imageRef = ref(storage, 'images/' + imageFileName);
      await deleteObject(imageRef); // Delete the file
  
    } catch (error) {
      console.error('Error removing additional image:', error);
      alert('Failed to remove additional image. Please try again.');
    }
  };
  

  const getImageFileName = (imageURL) => {
    // Extract the file name from the imageURL
    const startIndex = imageURL.lastIndexOf('/') + 1;
    return imageURL.substring(startIndex);
  };

  

  return (
    <form className="edit-product-form" onSubmit={handleSubmit}>
      <h3>Edit Product</h3>
      <label>Main Image:</label>
      <input type="file" accept="image/*" onChange={handleMainImageChange} />
      {mainImage && (
        <img src={URL.createObjectURL(mainImage)} alt="Main" />
      )}
      <label>Additional Images:</label>
      <input type="file" accept="image/*" onChange={handleAdditionalImagesChange} multiple />
      {existingAdditionalImages.length > 0 && (
        <div className="additional-images">
          <h4>Existing Additional Images:</h4>
          {existingAdditionalImages.map((imageURL, index) => (
            <div key={index} className="existing-additional-image">
              <img src={imageURL} alt={`Existing Additional ${index + 1}`} />
              <button type="button" onClick={() => removeExistingAdditionalImage(index)}>Remove</button>
            </div>
          ))}
        </div>
      )}
      {additionalImages.length > 0 && (
        <div className="additional-images">
          <h4>Additional Images to be Added:</h4>
          {additionalImages.map((image, index) => (
            <div key={index} className="additional-image">
              <img src={URL.createObjectURL(image)} alt={`Additional ${index + 1}`} />
              <button type="button" onClick={() => removeAdditionalImage(index)}>Remove</button>
            </div>
          ))}
        </div>
      )}
      <label>Name:</label>
      <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
      <label>Description:</label>
      <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
      <label>Price:</label>
      <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} />
      <div className="button-container">
        <button type="submit" className="save-button">Save</button>
        <button type="button" onClick={onCancel} className="cancel-button">Cancel</button>
      </div>
    </form>
  );
};

export default EditProductForm;
