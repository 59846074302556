// ArrowButtons.js
import React from 'react';

const ArrowButtons = ({ handlePrevImage, handleNextImage }) => {
  return (
    <div className="arrow-buttons">
      <button className="arrow left" onClick={handlePrevImage}>&#10094;</button>
      <button className="arrow right" onClick={handleNextImage}>&#10095;</button>
    </div>
  );
};

export default ArrowButtons;
