import React from 'react';
import './FullScreenImage.css';

const FullScreenImage = ({ src, alt, onClose }) => {
  return (
    <div className="full-screen-image-overlay" onClick={onClose}>
      <div className="full-screen-image-container">
        <img src={src} alt={alt} className="full-screen-image" />
        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default FullScreenImage;
