// DashBoardLoginForm.js
import React, { useState } from 'react';
import './DashBoardLoginForm.css';
import { Link } from 'react-router-dom';


const DashBoardLoginForm = ({ onLogin, onSignUp }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [failedLoginAttempts, setFailedLoginAttempts] = useState(0);

  //hard coding user name and password, will later know how to authenticate 
  const hardCodedUserName = 'darlingjack';
  const hardCodedPassword = 'ante7424GH';
  
  //function to handle the submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // checking to see if they match
    if (username === hardCodedUserName && password === hardCodedPassword){
        //then return login
        onLogin({username, password});
    }
    else{
        //if they do not match
        setFailedLoginAttempts(failedLoginAttempts + 1);
        alert('Invalid Username or password');

        //checking to see if failed attempt has exceeded the threshold
        const maxAttempts = 2;
        if(failedLoginAttempts >= maxAttempts){
          alert('You have exceeded the maximum number of login attempts.');
          window.location.href="/";
          
        }
        
    }
  };
 // the returning JSX 
  return (
    <div className="login-form-container">
      <h2 className="login-header">Login restricted</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-group">
          <label htmlFor="username" className="form-label">Username:</label>
          <input
            type="text"
            id="username"
            className="form-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-label">Password:</label>
          <input
            type="password"
            id="password"
            className="form-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="login-button">Login</button>
      </form>
      <Link to="/">Go Home</Link>
    </div>
  );
};

export default DashBoardLoginForm;
